<template>
  <loginForm type="register" />
</template>

<script>
import loginForm from '@/components/loginForm'
import webviewBack from '@/mixins/webviewBack'

export default {
  name: 'Register',
  mixins: [webviewBack],
  components: {
    loginForm
  }
}
</script>
